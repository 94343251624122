import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";

export default function Idle() {
  const history = useHistory();

  return (
    <AppLayout title={""} style={{padding:0}}>
      <img
        src={process.env.PUBLIC_URL + "/72.png"}
        alt="Open Cabinet"
        style={{ width: "100%", height: "100%" }}
      />
    </AppLayout>
  );
}
