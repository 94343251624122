import React from "react";
import Grid from "@material-ui/core/Grid";
import logo from "../FoodSVG.svg";
export default function Loader() {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: "100%" }}
    >
      <img
        src={logo}
        className="rotate"
        alt="xixa"
        style={{ width: "150px" }}
      />
    </Grid>
  );
}
