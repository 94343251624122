import React from "react";
import Button from "./button";
import Container from "./container";
import { useHistory } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";

export default function Footer() {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  console.log(locale);
  const onClick = () => {
    history.push("/cabinet");
  };
  return (
    <Container className="footer">
      <Button className="event-button" onClick={onClick}>
        <i className="fas fa-plus" />
        {Languages[locale].openBuyBye}
      </Button>
    </Container>
  );
}
