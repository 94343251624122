import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import { createBrowserHistory } from "history";
import Events from "./components/events";
import Blank from "./pages/blank";
import Idle from "./pages/idle";
import WaitDoor from "./pages/waitDoor";
import DoorOpen from "./pages/doorOpen";
import ProcessingPayment from "./pages/processingPayment";
import PurschaseValue from "./pages/purschaseValue";

import CardProblem from "./pages/cardProblem";
import TechProblem from "./pages/techProblem";
import Maintenance from "./pages/maintenance";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import Wrapper from "./pages/Wrapper";
import AuthService from "./services/auth-service";

function PrivateRoute({ component: Component, ...rest }) {
  const user = AuthService.getCurrentUser();
  let _render;
  if (user && user._id) {
    _render = (props) => {
      return <Component {...props} />;
    };
  } else {
    _render = (props) => {
      return <Redirect to="/signin" />;
    };
  }
  return <Route {...rest} render={(props) => _render(props)} />;
}
var hist = createBrowserHistory();
ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />

    <Router history={hist}>
      <App>
        <Wrapper>
          <Switch>
            <Route path="/blank" component={Blank} />
            <Route path="/wait" component={WaitDoor} />
            <Route path="/doorOpen" component={DoorOpen} />
            <Route path="/processing/payment" component={ProcessingPayment} />
            <Route path="/purchase/value" component={PurschaseValue} />
            <Route path="/problem/card" component={CardProblem} />
            <Route path="/problem/tech" component={TechProblem} />
            <Route path="/problem/maintenance" component={Maintenance} />
            <Route path="/" component={Idle} />
          </Switch>
        </Wrapper>
      </App>
    </Router>
  </ThemeProvider>,
  document.querySelector("#root")
);
serviceWorker.unregister();
