import React from "react";
import classNames from "classnames";
const cx = classNames;
const Container = React.forwardRef(
  ({ className, as: Component, children, ...props }, ref) => {
    const classNames = cx("container", className);

    return (
      <Component className={classNames} ref={ref} {...props}>
        {children}
      </Component>
    );
  }
);

Container.defaultProps = {
  as: "div",
};
export default Container;
