import React from "react";
import classNames from "classnames";
const cx = classNames;

export default function Button({ children, className, ...rest }) {
  const classNames = cx("button", className);
  return (
    <button className={classNames} {...rest}>
      {children}
    </button>
  );
}
