import React, { useEffect } from "react";
import {
  useSprings,
  interpolate,
  useTransition,
  animated,
  useSpring,
} from "react-spring";
import { useGesture } from "react-use-gesture";
import "../styles/events.css";
import Button from "./button";
import Footer from "./footer";
import Container from "./container";
import BottomNav from "./bottomNav";
import LOGO from "./logo";
import { Grid, Typography } from "@material-ui/core";
import {
  Whatshot as WhatshotIcon,
  Favorite as FavoriteIcon,
  Done as DoneIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import BuyByeService from "../services/buybye-service";

import { useHistory } from "react-router-dom";
import Loader from "../pages/loader";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
const useStyles = makeStyles((theme) => ({
  color: {
    color: `${theme.palette.primary.main} !important`,
  },
}));
const R = require("ramda");

const Address = ({ data }) => (
  <div>
    <address className="header__address">
      <i className="fas fa-map-marker-alt" />
      {data ? data.place : ""}
    </address>
  </div>
);

const HeaderTitle = ({ data }) => (
  <h1 className="header__title">{data ? data.product.name : ""}</h1>
);

const HeaderDate = ({ data }) => (
  <time className="header__date">{data ? data.date : ""}</time>
);

const HeaderTop = ({ data }) => (
  <div className="header__top">
    <HeaderTitle data={data} />
    <HeaderDate data={data} />
  </div>
);

const HeaderItem = ({ data }) => {
  return (
    <Container className="header__item">
      <HeaderTop data={data} />
      <Address data={data} />
    </Container>
  );
};

const Header = ({ isOpened, data, index, as: Component }) => {
  const [prev] = React.useState({ index });

  const transitions = useTransition(index, (p) => p, {
    from: (newIdx) => {
      const dir = newIdx - prev.index;
      prev.index = newIdx;
      return { opacity: 0, transform: `translate3d(0, ${100 * dir}%, 0)` };
    },
    enter: () => {
      return { opacity: 1, transform: `translate3d(0, 0%, 0)` };
    },
    leave: (newIdx) => {
      const dir = newIdx - index;
      return { opacity: 0, transform: `translate3d(0, ${100 * dir}%, 0)` };
    },
  });

  return (
    <div className="header">
      {transitions.map(({ item, props, key }) => {
        let style = { ...props };
        if (isOpened) style.display = "none";
        return (
          <animated.div key={key} style={style}>
            <Component data={data[item]} />
          </animated.div>
        );
      })}
    </div>
  );
};

const MAX_COUNT = 3;

const onNext = (
  { args: [index], down },
  { isNextTrigger, getStartData, onChange, ref, drag },
  i
) => {
  if (!isNextTrigger || down) {
    return;
  }

  if (index === i) {
    return {
      ...getStartData(i),
      x: -ref.current.offsetWidth,
      sc: 1,
      opacity: 1,
      immediate: false,
      onStart: () => {
        onChange(index + 1);
      },
      onRest: () => {
        drag.cancel();
      },
    };
  }

  if (i - index > 0) {
    const currentIndex = i - index - 1;
    return {
      ...getStartData(currentIndex),
      immediate: false,
      onStart: undefined,
      onRest: undefined,
    };
  }
};

const onPrev = (
  { args: [index], down },
  { isPrevTrigger, getStartData, onChange, drag },
  i
) => {
  if (!isPrevTrigger || down) {
    return;
  }

  if (index > 0 && i - index >= -1 && isPrevTrigger) {
    const currentIndex = i - index + 1;

    let indexConf = {
      onStart: undefined,
      onRest: undefined,
    };

    if (index === i) {
      indexConf.onStart = () => {
        onChange(index - 1);
      };

      indexConf.onRest = () => {
        drag.cancel();
      };
    }

    return {
      ...getStartData(currentIndex),
      immediate: false,
      ...indexConf,
    };
  }
};

const isNotTarget = ({ args: [index] }, data, i) => {
  if (index !== i) {
    return {
      onStart: undefined,
      onRest: undefined,
    };
  }
};

const isNotDown = ({ down }, { drag }) => {
  if (down) {
    return;
  }

  return {
    x: 0,
    immediate: false,
    onRest: () => {
      drag.cancel();
    },
  };
};

const onDefault = ({ movement }) => ({
  x: movement[0],
  immediate: true,
  reset: false,
  onStart: undefined,
  onRest: undefined,
});

const useIsDrag = () => {
  const [state] = React.useState({});
  const setIsDrag = (movement) => {
    const xMod = Math.abs(movement[0]);

    if (xMod > 2) {
      state.isDrag = true;
    }
  };

  const cancel = () => {
    state.isDrag = false;
  };

  return {
    state,
    setIsDrag,
    cancel,
  };
};

const useSlider = ({ data, onChange, ref, onClick }) => {
  const totalItems = data.length;
  const drag = useIsDrag();
  const getStartData = (i) => {
    const isVisible = i <= MAX_COUNT - 1;
    const multiplier = i === 0 ? 1 : 1.1;

    return {
      x: isVisible ? (i * 28) / multiplier : (MAX_COUNT - 1) * 28,
      y: 0,
      sc: isVisible
        ? 1 / (MAX_COUNT / (MAX_COUNT * multiplier - i))
        : 1 / MAX_COUNT,
      opacity: isVisible ? 1 / (MAX_COUNT / (MAX_COUNT - i)) : 0,
      display: "block",
      pointerEvents: i === 0 ? "" : "none",
      boxShadow:
        i === 0
          ? "0 10px 40px rgba(0, 0, 0, .2)"
          : "0 0px 0px rgba(0, 0, 0, .2)",
      position: "",
      transformOrigin: "",
      width: "",
      height: "",
    };
  };
  const [props, set] = useSprings(totalItems, getStartData);

  const bind = useGesture({
    onDrag: (state) => {
      const {
        args: [index],
        down,
        movement,
        cancel,
      } = state;
      const isNextTrigger = movement[0] <= -100 && index !== totalItems - 1;
      const isPrevTrigger = movement[0] >= 24;

      const data = {
        isNextTrigger,
        isPrevTrigger,
        getStartData,
        onChange,
        ref,
        drag,
      };

      if (down) {
        drag.setIsDrag(movement);
      }

      if ((isNextTrigger || isPrevTrigger) && down) {
        cancel(index);
      }

      set((i) => {
        const action = R.find((fn) => fn(state, data, i), [
          onNext,
          onPrev,
          isNotTarget,
          isNotDown,
          onDefault,
        ]);

        if (action) {
          return action(state, data, i);
        }
      });
    },
  });

  const map = (component) => {
    console.log(component);
    return props.map(
      (
        { x, y, display, sc, opacity, pointerEvents, boxShadow, ...rest },
        i
      ) => {
        const root = {
          key: i,
          style: {
            display,
            transform: interpolate(
              [x, y, sc],
              (x, y, s) => `translate3d(${x}px, ${y}px, 0) scale(${s})`
            ),
            opacity,
            pointerEvents,
            zIndex: data.length - i,
            ...rest,
          },
          ...bind(i),
        };

        const inner = {
          style: {
            boxShadow,
          },
        };

        const onClickHandler = (...args) => {
          if (drag.state.isDrag) {
            return;
          }

          onClick(set, i, ...args);
        };

        return component({
          root,
          inner,
          ref,
          item: data[i],
          onClick: onClickHandler,
        });
      }
    );
  };

  return {
    map,
    ref,
    set,
    getStartData,
  };
};

const Slider = ({ slider }) => {
  const { map, ref } = slider;

  return (
    <div className="slider" ref={ref}>
      <div className="slider__container">
        {map(({ root, inner, onClick, item, itemRef }) => {
          const videoSrc = R.path(["video"])(item);
          const videoPoster = R.path(["poster"])(item);

          return (
            <animated.div
              ref={itemRef}
              className="slider__item"
              onClick={onClick}
              {...root}
            >
              <animated.div
                className="slider__inner"
                {...inner}
                style={{
                  border: "1px solid #eee",
                  //backgroundImage: `url(${R.path(["imageUrl"])(item)})`,
                  ...inner,
                }}
              >
                <img
                  src={`${R.path(["imageUrl"])(item)}`}
                  style={{ margin: "auto", maxHeight: "98%", maxWidth: "98%" }}
                ></img>
                {videoSrc && (
                  <video
                    src={videoSrc}
                    poster={videoPoster}
                    autoPlay
                    loop
                    muted
                    playsInline
                  />
                )}
              </animated.div>
            </animated.div>
          );
        })}
      </div>
    </div>
  );
};

const ScreenModal = ({ onClose, isOpen, children }) => {
  const mainSpring = useSpring({
    from: { x: 0 },
    x: isOpen ? 1 : 0,
    pointerEvents: isOpen ? "" : "none",
    display: isOpen ? "flex" : "none",
  });

  const contentSpring = useSpring({
    from: { x: 0 },
    x: isOpen ? 1 : 0,
    pointerEvents: isOpen ? "" : "none",
    display: isOpen ? "flex" : "none",
    config: { duration: 400 },
  });

  return (
    <ScreenModalRoot mainSpring={mainSpring}>
      <ScreenModalTopBar mainSpring={mainSpring}>
        <Button
          className="nav-bar__back"
          onClick={onClose}
          style={{ color: "black" }}
        >
          <ArrowBackIcon style={{ fontSize: "1.3em", marginRight: "5px" }} />
        </Button>
      </ScreenModalTopBar>
      <ScreenModalContainer
        mainSpring={mainSpring}
        contentSpring={contentSpring}
        children={children}
      />
    </ScreenModalRoot>
  );
};

const ScreenModalRoot = ({ mainSpring, children }) => (
  <animated.div
    className="screen-modal-root"
    style={{
      pointerEvents: mainSpring.pointerEvents,
      display: mainSpring.display,
    }}
    children={children}
  />
);

const ScreenModalTopBar = ({ mainSpring, children }) => (
  <Container
    as={animated.div}
    style={{
      color: "white",
      opacity: mainSpring.x.interpolate({ range: [0, 1], output: [0, 1] }),
      transform: mainSpring.x
        .interpolate({
          range: [0, 1],
          output: [100, 0],
        })
        .interpolate((x) => `translate3d(0, ${x}%, 0`),
    }}
    children={children}
  />
);

const ScreenModalContainer = ({ mainSpring, contentSpring, children }) => (
  <animated.div
    className="screen-modal"
    style={{
      transform: mainSpring.x
        .interpolate({
          range: [0, 1],
          output: [100, 0],
        })
        .interpolate((x) => `translate3d(0, ${x}%, 0`),
    }}
  >
    <animated.div
      className="screen-modal__scroll"
      style={{
        paddingBottom: "11%",
        opacity: contentSpring.x.interpolate({ range: [0, 1], output: [0, 1] }),
        transform: contentSpring.x
          .interpolate({
            range: [0, 1],
            output: [100, 0],
          })
          .interpolate((x) => `translate3d(0, ${x}%, 0`),
      }}
    >
      {children}
    </animated.div>
  </animated.div>
);

const ScreenModalContent = ({ data, locale }) => (
  <div>
    <Container className="screen-modal__content">
      <HeaderTitle data={data} />
      <Address data={data} />
      <Grid container>
        <Grid item xs={4}>
          <Typography>
            <FavoriteIcon style={{ fontSize: "inherit" }} />{" "}
            {Math.floor(Math.random() * (125 - 5) + 5)} Favorite
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>
            <WhatshotIcon style={{ fontSize: "inherit" }} />{" "}
            {data.cal || data.kcal || ` 50 KCal`}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography color="primary">
            {parseInt(data.total) > 0 ? (
              <strong>
                <DoneIcon style={{ fontSize: "inherit" }} /> Available
              </strong>
            ) : (
              ""
            )}
          </Typography>
        </Grid>
      </Grid>
    </Container>
    <Container style={{ marginBlockEnd: "3em" }}>
      <p
        dangerouslySetInnerHTML={{
          __html: data.description[locale] || "",
        }}
      />
    </Container>
  </div>
);

const Screen = () => {
  const history = useHistory();
  const classes = useStyles();
  const [current, setCurrent] = React.useState(0);
  const [isOpened, setIsOpened] = React.useState(false);
  const ref = React.useRef();
  const [pages, setPages] = React.useState(undefined);
  const { locale } = React.useContext(LocaleContext);
  const slider = useSlider({
    data: pages || [],
    onChange: setCurrent,
    ref,
    onClick: (set, index, event) => {
      console.log(event);
      const el = event.currentTarget;
      const rect = el.getBoundingClientRect();
      console.log(rect);
      console.log(window.innerHeight);

      set((i) => {
        if (index === i) {
          setIsOpened(true);

          return {
            x: window.innerWidth / 2 - rect.width / 2 - rect.x + 5,
            y: -rect.y + 25,
            //y: -window.innerHeight / 2 + window.innerHeight / 2 - rect.y + 25,
            //sc: Math.max(ws, hs),
            pointerEvents: "none",
            transformOrigin: "center center",
          };
        }
      });
    },
  });

  useEffect(() => {
    async function main() {
      let res = await BuyByeService.getProducts();
      console.log(res);
      if (res.success) {
        let aux = res.result.products.map((r) => {
          return { ...r, ...r.product, ...r.product.metadata, ...r.stock };
        });

        console.log("DATA PAGES", aux);
        function shuffle(array) {
          var currentIndex = array.length,
            temporaryValue,
            randomIndex;

          // While there remain elements to shuffle...
          while (0 !== currentIndex) {
            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
          }

          return array;
        }
        //shuffle(aux);
        setPages(aux);
      }
    }
    main();
  }, [history]);

  const onCloseClick = () => {
    slider.set((i) => {
      if (i === current) {
        setIsOpened(false);
        return {
          ...slider.getStartData(0),
          transformOrigin: "center center",
        };
      }
    });
  };

  const mainSpring = useSpring({
    from: { x: 0 },
    x: !isOpened ? 1 : 0,
  });
  if (!pages) {
    return (
      <AppLayout>
        <Loader></Loader>
      </AppLayout>
    );
  }

  const data = pages.length > 0 ? pages[current] : [];

  if (data.length == 0) {
    return (
      <section className="events-screen">
        <Container
          as={animated.div}
          className="nav-bar"
          style={{
            opacity: mainSpring.x.interpolate({
              range: [0, 1],
              output: [0, 1],
            }),
            transform: mainSpring.x
              .interpolate({
                range: [0, 1],
                output: [-100, 0],
              })
              .interpolate((x) => `translate3d(0, ${x}%, 0`),
          }}
        >
          <Button className="nav-bar__back">
            <LOGO />
          </Button>
        </Container>
        <Footer />
        <Container>
          <div className="credits">
            Created with <span className="love"></span> by{" "}
            <a
              href="https://www.reckon.ai"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.color}
            >
              Reckon.ai
            </a>
          </div>
        </Container>
        <BottomNav match location />
      </section>
    );
  }

  return (
    <section className="events-screen">
      <Container
        as={animated.div}
        className="nav-bar"
        style={{
          opacity: mainSpring.x.interpolate({ range: [0, 1], output: [0, 1] }),
          transform: mainSpring.x
            .interpolate({
              range: [0, 1],
              output: [-100, 0],
            })
            .interpolate((x) => `translate3d(0, ${x}%, 0`),
        }}
      >
        <Button className="nav-bar__back">
          <LOGO />
        </Button>
      </Container>
      <Header
        isOpened={isOpened}
        data={pages}
        index={current}
        as={HeaderItem}
      />
      <div className="events-screen__slider">
        <Slider slider={slider} />
      </div>
      <ScreenModal onClose={onCloseClick} isOpen={isOpened}>
        <ScreenModalContent
          key={data ? data.id : Math.random()}
          data={data}
          locale
        />
      </ScreenModal>
      <Footer />
      <Container>
        <div className="credits">
          Created with <span className="love"></span> by{" "}
          <a
            href="https://www.reckon.ai"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.color}
          >
            Reckon.ai
          </a>
        </div>
      </Container>
      <BottomNav match location />
    </section>
  );
};

const AppLayout = ({ children, ...rest }) => {
  return (
    <div className="app-layout" {...rest}>
      {children}
    </div>
  );
};

const App = () => {
  return (
    <AppLayout style={{ overflowY: "scroll" }}>
      <Screen />
    </AppLayout>
  );
};
export default App;
